<template>
  <div class="NotFound py-20">
    <div class="constrain">
      <div class="row-10">
        <div class="col-8 offset-1">

          <h1>
            What, so everyone’s supposed to sleep every single night now?
            You realize that nighttime makes up half of all time?
          </h1>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">

</style>
